$(".index-slider").slick(), $(".map-block").hover(function () {
    $(this).closest(".index-map__block").find(".index-map__block-legend").toggleClass("legend-visible")
}), $(".js-show-modal").click(function () {
    $("body").addClass("body-mask"), $(".modal-rent").addClass("modal-rent--shown")
}), $(".modal-rent__close").click(function () {
    $("body").removeClass("body-mask"), $(".modal-rent").removeClass("modal-rent--shown")
}), $(".modal-done__close").click(function () {
    $("body").removeClass("body-mask"), $(".modal-done").removeClass("modal-done--shown")
}), $(".modal-done__hide").click(function () {
    $("body").removeClass("body-mask"), $(".modal-done").removeClass("modal-done--shown")
}),
//        $(".js-modal-rent--sbmt").click(function() {
//        $(".modal-rent").removeClass("modal-rent--shown"), $(".modal-done").addClass("modal-done--shown")
//    }),
    $(".header__burger").click(function () {
        $(".header__bot").toggleClass("header__bot--visible")
    }), $(".about-slider__content").slick({
    infinite: !0,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: !0,
    draggable: !1,
    responsive: [{
        breakpoint: 641,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    }, {
        breakpoint: 481,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }]
}), $("#slick-for").slick({
    draggable: !1,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: !0,
    arrows: !1,
    speed: 500,
    fade: !0,
    dots: !1,
    asNavFor: "#slick-nav",
    responsive: [{
        breakpoint: 800,
        settings: {
            arrows: !1
        }
    }]
}), $("#slick-nav").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: !1,
    dots: !1,
    infinite: !0,
    speed: 500,
    asNavFor: "#slick-for",
    focusOnSelect: !0,
    customPaging: !0,
    responsive: [{
        breakpoint: 800,
        settings: {
            arrows: !1
        }
    }]
}),
    function (e) {
        jQuery.fn.lightTabs = function (s) {
            var o = function () {
                tabs = this, i = 0, showPage = function (s) {
                    e(tabs).children("div").children("div").hide(), e(tabs).children("div").children("div").eq(s).show(), e(tabs).children("ul").children("li").removeClass("active"), e(tabs).children("ul").children("li").eq(s).addClass("active")
                }, showPage(0), e(tabs).children("ul").children("li").each(function (s, o) {
                    e(o).attr("data-page", i), i++
                }), e(tabs).children("ul").children("li").click(function () {
                    showPage(parseInt(e(this).attr("data-page")))
                })
            };
            return this.each(o)
        }
    }(jQuery), $(document).ready(function () {
    $(".tabs").lightTabs();
    //alert(12);
    $('.header__bot nav ul.header__bot-list > li').on('mouseenter', function () {
        if ($(window).width() > '768') {
            $(this).find('ul').slideDown();
        }
    })

    $('.header__bot nav ul.header__bot-list > li').on('mouseleave', function () {
        if ($(window).width() > '768') {
            $(this).find('ul').slideUp();
        }
    })


    $('.header__bot nav ul.header__bot-list > li.header__bot-menu__item').on('click', function (e) {
        if ($(window).width() < '768') {
            if ($(this).find('ul').length > 0) {
                if (!$(this).find('ul').is(":visible")) {
                    e.preventDefault();
                    $(this).find('ul').slideToggle();
                } else {
                    $(this).find('ul').slideToggle();
                }
            }
        }
    })
    $(".masked-phone").mask("+7 (999) 999-99-99");
});